import React from "react";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { GatsbyImage } from "gatsby-plugin-image";
import * as styles from './blogPost.module.css';
import GlobalLayout from "../layouts/globalLayout";

const Bold = ({ children }) => <span className={styles.bold}>{children}</span>
const Text = ({ children }) => <p className={styles.alignCenter}>{children}</p>
const HEADING1 = ({ children }) => <h1 className={styles.heading1}>{children}</h1>
const HEADING2 = ({ children }) => <h2 className={styles.heading2}>{children}</h2>
const HEADING3 = ({ children }) => <h3 className={styles.heading3}>{children}</h3>
const HEADING4 = ({ children }) => <h4 className={styles.heading4}>{children}</h4>
const HEADING5 = ({ children }) => <h5 className={styles.heading5}>{children}</h5>
const HEADING6 = ({ children }) => <h6 className={styles.heading6}>{children}</h6>
const Paragraph = ({ children }) => <p className={styles.paragraph}>{children}</p>
const OrderedList = ({ children }) => <ol className={styles.orderedList}>{children}</ol>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.HEADING_1]: (node, children) => <HEADING1>{children}</HEADING1>,
    [BLOCKS.HEADING_2]: (node, children) => <HEADING2>{children}</HEADING2>,
    [BLOCKS.HEADING_3]: (node, children) => <HEADING3>{children}</HEADING3>,
    [BLOCKS.HEADING_4]: (node, children) => <HEADING4>{children}</HEADING4>,
    [BLOCKS.HEADING_5]: (node, children) => <HEADING5>{children}</HEADING5>,
    [BLOCKS.HEADING_6]: (node, children) => <HEADING6>{children}</HEADING6>,
    [BLOCKS.PARAGRAPH]: (node, children) => <Paragraph>{children}</Paragraph>,
    [BLOCKS.OL_LIST]: (node, children) => <OrderedList>{children}</OrderedList>,
    [BLOCKS.EMBEDDED_ASSET]: node => {
      return (
        <GatsbyImage image={node.data.target.gatsbyImageData} />
      )
    },
  },
}

const BlogTemplate = ({ pageContext }) => {
  const { blog } = pageContext;

  return (
    <GlobalLayout>
      <div className={styles.body}>
        {blog.blogPostWithMedia && renderRichText(blog.blogPostWithMedia, options)}
      </div>
    </GlobalLayout>
  )
}

export default BlogTemplate;