// extracted by mini-css-extract-plugin
export var body = "blogPost-module--body--10Qzs";
export var bold = "blogPost-module--bold--qjr2w";
export var alignCenter = "blogPost-module--alignCenter--C1aYt";
export var heading1 = "blogPost-module--heading1--2MAYc";
export var heading2 = "blogPost-module--heading2--o8gvM";
export var heading3 = "blogPost-module--heading3--wO2Qf";
export var heading4 = "blogPost-module--heading4--32CmT";
export var heading5 = "blogPost-module--heading5--1F0r-";
export var heading6 = "blogPost-module--heading6--1t6O3";
export var paragraph = "blogPost-module--paragraph--1wBgl";
export var orderedList = "blogPost-module--orderedList--1VxON";